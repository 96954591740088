<template>
  <el-container>
    <el-header>
      <div class="header">
        <div>
          <el-menu
            default-active="/index"
            text-color="#303122"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item style="padding:0px;" index="/index">
              <img src="@/assets/img/profile.png" class="profile" />
              <span class="title">知识追寻者</span>
            </el-menu-item>
            <fragment v-for="category in categoryTree" :key="category.id">
              <el-submenu
                v-if="category.children.length>0"
                :key="category.id"
                :index="category.path"
              >
                <template slot="title">{{category.categoryName}}</template>
                <el-menu-item
                  v-for="children in category.children"
                  :index="children.path"
                  :key="children.id"
                >{{children.categoryName}}</el-menu-item>
              </el-submenu>
              <el-menu-item
                v-else
                :index="category.path"
                :key="category.id"
              >{{category.categoryName}}</el-menu-item>
            </fragment>
          </el-menu>
        </div>
        <div class="write">
          <a href="#" ref="nofollow" @click="toArticle()">
            <i class="el-icon-edit-outline"></i>写文章
          </a>
        </div>
        <div v-if="!isLogin()" class="login">
          <a href="#" @click="toPage('login')">登陆/注册</a>
        </div>
        <!-- v-if="this.permission(['ROLE_USER'])" -->
        <div v-else @click="showvVertical()" class="user">
          <el-avatar :size="30" :src="userInfo.icon" :alt="userInfo.name"></el-avatar>
          <div v-if="showMenu" class="vertical">
            <div @click="toHomePage()" class="menu-item">
              <span  class="el-icon-s-home" >个人主页</span>
            </div>
            <div  @click="toPage('articleTag')" class="menu-item">
              <span  class="el-icon-price-tag" >标签管理</span>
            </div>
             <div @click="toPage('articleDraft')" class="menu-item">
              <span  class="el-icon-suitcase">草稿箱</span>
            </div>
            <div  @click="toPage('artilceAccuse')" class="menu-item">
              <span  class="el-icon-warning" >举报信息</span>
            </div>
            <div @click="toPage('articleManage')" v-if="this.permission(['ROLE_ADMIN'])" class="menu-item">
              <span  class="el-icon-s-tools" >后台管理</span>
            </div>
            <div @click="logout()" class="menu-item">
              <span class="iconfont" >&#xe600;登出</span>
            </div>
          </div>
        </div>
      </div>
    </el-header>
    <el-container class="home-container">
      <el-main class="home-main">
        <router-view />
      </el-main>
      <!-- <el-footer class="foot-pc">
        Copyright © 2021 知识追寻者.保留所有权利. IPC备案:
        <a
          style="text-decoration: none;color:inherit;"
          ref="nofollow"
          href="https://beian.miit.gov.cn/state/outPortal/loginPortal.action#/Integrated/index"
        >闽ICP备2021002267号 </a>
        <span>本站联系邮箱： lsc_106@126.com 官方微信公众号:知识追寻者</span>
      </el-footer> -->
    </el-container>
  </el-container>
</template>

<script>
import { encrypt } from "@/utils/encoder.js";
export default {
  data() {
    return {
      categoryTree: {},
      visible: false,
      showMenu: false
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.permission.user;
    }
  },
  created() {
    this.getCategoryTree();
  },
  methods: {
    async getCategoryTree() {
      const { data: res } = await this.$api.getCategoryTree();
      this.categoryTree = res.data;
    },
    handleSelect(key, keyPath) {
      if (key == "/index") {
        this.$router.push(key);
      } else {
        this.$router.push({
          name: "category",
          params: { categoryId: `${key}` }
        });
      }
    },
    // 跳转写文章
    toArticle() {
      if (!this.isLogin()) {
        this.$alert("只有登陆用户才可以写文章哟", "提示", {
          confirmButtonText: "确定",
          callback: action => {}
        });
        return;
      }
      this.$router.push({ name: "articleEdit" });
    },
    // 跳转页面
    toPage(value) {
      this.$router.push({ name: value });
    },
    // 判定是否已经登陆
    isLogin() {
      if (this.$store.state.permission.user.username) {
        return true;
      } else {
        return false;
      }
    },
    // 登出
    async logout() {
      await window.localStorage.removeItem('token')
      await this.$store.dispatch("clear");
      this.$router.push({ name: "login" });
    },
    // 跳转个人首页
    toHomePage() {
      this.$router.push({
        name: "homepage",
        params: { username: encrypt(this.userInfo.username) }
      });
    },
    showvVertical() {
      if (this.showMenu == true) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    }
  }
};
</script>

<style scoped>
@import "../assets/css/home.css";
</style>